import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppFormComplete,
} from "../App/selectors";
import { inputChange, verify } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const Verify = ({
  isLogin,
  dVerify,
  dInputChange,
  formValue,
  formValid,
  formComplete,
  isPageReady,
  location,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }

  if (isPageReady && formComplete.VERIFY) {
    return <Redirect to="/login" />;
  }

  // Handle the email change and convert it to lowercase
  function handleEmailChange(e) {
    let email = e;
    email = email.toLowerCase(); // Convert to lowercase
    dInputChange(email, "otp"); // Dispatch the change to redux
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Verify Email</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.verify}
            formValid={formValid.verify}
            handleInputChange={handleEmailChange} // Use custom handler
            submitLabel="Submit"
            handleSubmit={dVerify}
          />
        </div>
        <div className="link-block">
          <NavLink to="/login">
            <strong>Already Verified?</strong>
            <span className="mossai-text"> Login</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
  formValid: makeSelectAppFormValid(),
  formComplete: makeSelectAppFormComplete(),
});

function mapDispatchToProps(dispatch) {
  return {
    dInputChange: (value, fieldName) => {
      dispatch(inputChange("verify", fieldName, value)); // Dispatch the action with the lowercase email
    },
    dVerify: () => {
      dispatch(verify(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Verify);
