import React from "react";
import { NavHashLink } from "react-router-hash-link";
        const HowItWorks = () => (
        <section className="how-it-works pt-3">
    {/* <div className="container" data-aos="fade-up">
        <div className="content row">
        <h2>What if managing your daily clinical tasks could be as seamless as your patient care?</h2>
            <div className="col-md-6 col-lg-8 d-flex justify-content-between flex-column mt-4">
                <div className="box">
                    <p>
                    At ClinicianOS, we're building a comprehensive platform designed to streamline the everyday operations of healthcare providers. Our goal is to empower clinicians globally by providing tools that simplify administrative burdens, centralise knowledge, and offer efficient task management—all in one place. With ClinicianOS, you’ll have everything you need to enhance productivity, improve patient outcomes, and stay focused on what matters most: care.

                    </p>
                     <br/>
                </div>
                <div>
                    <NavHashLink className="btn btn-primary" to="/login">
                    Request early access
                    </NavHashLink>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-4">
                <ul className="mb-0">
                    <li>
                        <i className="bi bi-check-circle"></i>Administrative Assistant
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Knowledge Hub
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Logbook
                    </li>            
                </ul>
            </div>
        </div>
    </div>     */}
</section>
        );
export default HowItWorks;
