import React from "react";
const addLineBreak = (str: string) =>
  str.split('\n').map((subStr) => {
    return (
      <>
        {subStr}
        <br />
      </>
    );
  });
const OurInvestors = ({ investors }) => (
  <section id="team">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Meet the Team behind ClinicianOS</h2>
        {/* <h3>
          Honoring Our Dedicated{" "}
          <span className="mossai-text">Stakeholders and Mentors</span>
        </h3> */}
        <p className="fs-18">
          At ClinicianOS, we are a passionate group of healthcare professionals,
          technologists, and innovators united by a common goal—to simplify the
          lives of clinicians worldwide. Our team brings together decades of
          expertise in healthcare and cutting-edge technology to create a
          platform that truly understands the day-to-day challenges of clinical
          practice.
        </p>
      </div>
      <div className="our-investors row">
        {investors.map((investor) => (
          <div className="col-12 col-md-3" key={investor.name}>
            <a href={investor.link} target="_blank" rel="noreferrer">
              <div className="testimonial-item">
                <div className="profile mt-auto">
                  <img
                    src={`assets/img/team/${investor.img}.png`}
                    className="testimonial-img"
                    alt={`${investor.name} ${investor.title}`}
                  />
                </div>
                <h3>{investor.name}</h3>
                <h4>{investor.title}</h4>
                <p>{addLineBreak(investor.bio)}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default OurInvestors;
