// Import specific Firebase services
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDJrcHNESihr_jag5AqevBJXxPb9BKMLcw",
  authDomain: "clinicianos.firebaseapp.com",
  projectId: "clinicianos",
  storageBucket: "clinicianos.firebasestorage.app",
  messagingSenderId: "1018732638433",
  appId: "1:1018732638433:web:c99cddaece5e0a8c7407a0",
  measurementId: "G-SCESLFDJQ5"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };