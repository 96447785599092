import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectAppPlanList,
  makeSelectAppCurrentSubscription,
} from "../App/selectors";
import { addToCart, getPlan, resetFlow } from "../App/actions";
import { INVESTORS } from "../App/internalConfig";

import HeroBanner from "../../components/HeroBanner";
import HowItWorks from "../../components/HowItWorks";
import Problem from "../../components/Problem";
import Features from "../../components/Features";
import WhyMossAI from "../../components/WhyMossAI";
import Plans from "../../components/Plans";
import Resources from "../../components/Resources";
import Contact from "../../components/Contact";
import Gallery from "../../components/Gallery";
import OurInvestors from "../../components/OurInvestors";
import AboutUs from "../../components/AboutUs";

const Home = ({
  dGetPlan,
  dAddToCart,
  isLogin,
  planList,
  isPageReady,
  dResetFlow,
  currentSubscription,
  userDetails,
}) => {
  const [showPopup, setShowPopup] = useState(true); // Set initial state to true for immediate popup display
  const history = useHistory();

  const moveToPage = (pagePath) => {
    history.push(pagePath);
  };

  // Trigger popup immediately when the Home page loads
  useEffect(() => {
    if (isPageReady) {
      dGetPlan();
      dResetFlow();
    }
  }, [isPageReady, dGetPlan, dResetFlow]);

  // Close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <HeroBanner />
      <main id="main">
        <HowItWorks />
        <Problem />
        {planList && (
          <Plans
            plansAvailable={planList}
            addToCart={dAddToCart}
            isLogin={isLogin}
            moveToPage={moveToPage}
            userDetails={userDetails}
            isPlanActive={
              currentSubscription && currentSubscription[0]
                ? !currentSubscription[0].isTopUpAllowed &&
                  currentSubscription[0].isPlanActive
                : false
            }
          />
        )}
        <Features />
        <WhyMossAI />
        <Gallery />
        <AboutUs />
        <OurInvestors investors={INVESTORS} />
        <Contact />
      </main>

      {/* Popup Component */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-popup" onClick={handleClosePopup}>
              &times;
            </button>
            <img
              src="/assets/img/popup1.jpg" // Update with actual path
              alt="Webinar"
              style={{ width: "100%", height: "auto" }}
            />
            {/* <h3>Join Our Webinar!</h3>
            <p>Explore the role of AI in healthcare. Don’t miss out!</p> */}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  planList: makeSelectAppPlanList(),
  currentSubscription: makeSelectAppCurrentSubscription(),
});

function mapDispatchToProps(dispatch) {
  return {
    dGetPlan: () => {
      dispatch(getPlan());
    },
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dAddToCart: (plan) => dispatch(addToCart(plan)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Home);
