import React, { useState } from "react";
import PlanList from "../PlanList";
import Popup from "../Popup";
import { NavHashLink } from "react-router-hash-link";
const Plans = ({
plansAvailable,
        addToCart,
        isLogin,
        moveToPage,
        isPlanActive,
        userDetails
        }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(false);
    return (
            <section id="Get-Early-Access" className="pricing">
                <Popup
                    isOpen={showModal}
                    onSubmit={addToCart}
                    plan={currentPlan}
                    closeModal={() => setShowModal(false)}
                    moveToPage={moveToPage}
                    isLogin={isLogin}
                    userDetails={userDetails}
                    />
                <div className="container">
                    <div className="section-title">
                        <h2>Sign Up For The Public Beta</h2>
                        <p>
                        We’re thrilled to announce the upcoming launch of ClinicianOS, a groundbreaking platform designed to revolutionise how clinicians manage their daily tasks. Be part of something transformative by joining our beta release! 
<br/> <br/>Not only will you get early access to the platform, but you'll also play a pivotal role in shaping its future. By signing up and completing a short survey, you can provide valuable feedback that helps us tailor ClinicianOS to meet the needs of clinicians like you.

                        </p>
                        <div >
                        <NavHashLink className="btn btn-primary mt-4" to="/register">
                        Sign Me Up
                    </NavHashLink>
                </div>
                    </div>
            
                    {/* <div className="row no-gutters">
                        <ul class="nav nav-pills justify-content-end mb-5">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" aria-current="page" href="#annual">Annual</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#quaterly">Quarterly</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#monthly">Monthly</a>
                            </li>
                        </ul>
                        <div class="tab-content clearfix">
                            <div class="tab-pane active" id="annual">
                                {plansAvailable.map((plan) => (
                                <PlanList
                        price="annual"
                                    key={plan.name}
                                    userDetails={userDetails}
                                    plan={plan}
                                    isLogin={isLogin}
                                    isPlanActive={isPlanActive}
                                    addToCart={
                                                            plan.planType === "TOP-UP"
                                                                    ? () => {
                                                                addToCart(plan);
                                                                moveToPage("/cart");
                                                            }
                                                            : () => {
                                                                setCurrentPlan(plan);
                                                                setShowModal(true);
                                                            }
                                    }
                                    />
                                                ))}
                            </div>
                            <div class="tab-pane" id="quaterly">
                                {plansAvailable.map((plan) => (
                                <PlanList
                        price="quaterly"
                                    key={plan.name}
                                    userDetails={userDetails}
                                    plan={plan}
                                    isLogin={isLogin}
                                    isPlanActive={isPlanActive}
                                    addToCart={
                                                            plan.planType === "TOP-UP"
                                                                    ? () => {
                                                                addToCart(plan);
                                                                moveToPage("/cart");
                                                            }
                                                            : () => {
                                                                setCurrentPlan(plan);
                                                                setShowModal(true);
                                                            }
                                    }
                                    />
                                                ))}
                            </div>
                            <div class="tab-pane" id="monthly">
                                {plansAvailable.map((plan) => (
                                <PlanList
                        price="monthly"
                                    key={plan.name}
                                    userDetails={userDetails}
                                    plan={plan}
                                    isLogin={isLogin}
                                    isPlanActive={isPlanActive}
                                    addToCart={
                                                            plan.planType === "TOP-UP"
                                                                    ? () => {
                                                                addToCart(plan);
                                                                moveToPage("/cart");
                                                            }
                                                            : () => {
                                                                setCurrentPlan(plan);
                                                                setShowModal(true);
                                                            }
                                    }
                                    />
                                                ))}
                            </div>
                        </div>                                            
                    </div> */}
                </div>
            </section>
            );
};
export default Plans;
