import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Button } from "react-bootstrap";

const Header = ({
  logout,
  isPageReady,
  isLogin,
  name,
  profilePic,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Toggle the menu state (only for mobile)
  const toggleMenu = () => {
    if (isMobile) {
      setIsMenuOpen((prev) => !prev);
    }
  };

  // Check if the window width is mobile-sized
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile(); // check initially
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Sticky header and back-to-top visibility logic
  useEffect(() => {
    const isSticky = () => {
      const header = document.querySelector(".header");
      const scrollTop = window.scrollY;
      scrollTop >= 50
        ? header.classList.add("header-scrolled")
        : header.classList.remove("header-scrolled");
    };

    const isScrolledEnough = () => {
      const backToTop = document.querySelector(".back-to-top");
      const scrollTop = window.scrollY;
      scrollTop >= 100
        ? backToTop.classList.add("show")
        : backToTop.classList.remove("show");
    };

    window.addEventListener("scroll", isSticky);
    window.addEventListener("scroll", isScrolledEnough);

    return () => {
      window.removeEventListener("scroll", isSticky);
      window.removeEventListener("scroll", isScrolledEnough);
    };
  }, []);

  return (
    <header id="header" className="header fixed-top">
      <div className="container-fluid container-xl">
        <div className="row justify-content-between" style={{ height: "100px" }}>
          <div className="col-auto align-self-center">
            <NavHashLink className="logo d-block" to="/">
              <img src="assets/img/Logo-New.png" alt="ClinicianOS Logo" />
            </NavHashLink>
          </div>
          <div className="col-auto align-self-center">
            <nav
              id="navbar"
              className={isMenuOpen ? "navbar navbar-mobile" : "navbar"}
            >
              <ul>
                <li>
                  <NavHashLink to="/home#Why-ClinicianOS?" onClick={toggleMenu}>
                    Why ClinicianOS?
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink
                    to="/home#Get-Early-Access"
                    onClick={toggleMenu}
                  >
                    Get Early Access
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to="/home#features" onClick={toggleMenu}>
                    Features
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to="/home#aboutUs" onClick={toggleMenu}>
                    About
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to="/home#team" onClick={toggleMenu}>
                    Team
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to="/home#contact" onClick={toggleMenu}>
                    Contact Us
                  </NavHashLink>
                </li>
                {isPageReady && isLogin ? (
                  <>
                    <NavHashLink to="/profile" onClick={toggleMenu}>
                      Profile
                    </NavHashLink>
                    <NavLink to="/">
                      <Button
                        className="me-2"
                        onClick={() => {
                          logout();
                          toggleMenu();
                        }}
                      >
                        Logout
                      </Button>
                    </NavLink>
                  </>
                ) : (
                  <NavHashLink to="/login" onClick={toggleMenu}>
                    <Button className="me-2">Login</Button>
                  </NavHashLink>
                )}
              </ul>
              {isMobile && (
                <button
                  className="mobile-nav-toggle"
                  aria-expanded={isMenuOpen}
                  aria-label="Toggle navigation"
                  onClick={toggleMenu}
                >
                  <i className={isMenuOpen ? "bi bi-x" : "bi bi-list"} />
                </button>
              )}
            </nav>
          </div>
        </div>
      </div>
      <NavHashLink
        className="back-to-top d-flex align-items-center justify-content-center"
        to="/"
      >
        <i className="bi bi-arrow-up-short" />
      </NavHashLink>
    </header>
  );
};

export default Header;
