import React from "react";
const Problem = () => (
  <section id="Why-ClinicianOS?" className="problem">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        {/* <h2 className="text-center">Problem</h2> */}
        {/* <h3>
          Honoring Our Dedicated{" "}
          <span className="mossai-text">Stakeholders and Mentors</span>
        </h3>
        <p>
          Exceptional individuals who have illuminated our journey and fortified
          our endeavors over the past four years. These esteemed stakeholders
          and mentors have consistently demonstrated unwavering dedication,
          acting as both guiding lights and pillars of support, propelling us
          towards success and inspiring us to achieve new heights through their
          invaluable contributions and steadfast commitment.
        </p> */}
      </div>
      <div className="our-investors row">
        <div className="col-12 col-md-4">
          {/* <h1>hahhahhahahahah</h1> */}
          <div className="profile mt-auto d-flex justify-content-center">
            <img
              src={`assets/img/image5.jpg`}
              className="problem-img mb-4"
              alt="Problem"
            />
          </div>
          <p className="mb-4 fs-18 text-center">
          Increasing administrative tasks lead to clinician burnout.          </p>
        </div>
        <div className="col-12 col-md-4">
          {/* <h1>hahhahhahahahah</h1> */}
          <div className="profile mt-auto d-flex justify-content-center">
            <img
              src={`assets/img/image3.jpg`}
              className="problem-img mb-4"
              alt="Problem"
            />
          </div>
          <p className="mb-4 fs-18 text-center">
          Fragmented data hinders effective decision-making and care quality.          </p>
        </div>

        <div className="col-12 col-md-4">
          {/* <h1>hahhahhahahahah</h1> */}
          <div className="profile mt-auto d-flex justify-content-center">
            <img
              src={`assets/img/image6.jpg`}
              className="problem-img mb-4"
              alt="Problem"
            />
          </div>
          <p className="mb-4 fs-18 text-center">
          Centralised platform needed for accessible, ongoing medical education.          </p>
        </div>
      </div>
    </div>

    <div className="container" data-aos="fade-up">
      <div className="section-title">
        {/* <h2 className="text-center">Solution</h2> */}
        {/* <h3>
          Honoring Our Dedicated{" "}
          <span className="mossai-text">Stakeholders and Mentors</span>
        </h3> */}
        {/* <p className="mb-4 text-center">
          An integrated Operating System for clinician’s every professional need
        </p> */}
        <div className="profile mt-auto d-flex justify-content-center">
          {/* <img src={`assets/img/Infographic.png`} className=" mb-4" alt="Problem" /> */}
          <div className="infographic-img"></div>
        </div>
      </div>
    </div>
  </section>
);
export default Problem;
