import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will show back-to-top icon after a specific scrollable */
  const isSticky = (e) => {
    const backtotop = document.querySelector(".back-to-top");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? backtotop.classList.add("active")
      : backtotop.classList.remove("active");
  };
  return (
    <div>
      <footer id="footer">
        {/* <div className="footer-newsletter">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <h4>
                                        Join us in our journey at{" "}
                                        <span className="mossai-text">Moss.ai</span>
                                    </h4>
                                    <p>
                                        Even though we would be focused on regular product updates,
                                        news and, promotions; the founders would be engaging users in
                                        their head-space through some media. Enroll here to read that
                                        bit.
                                    </p>
                                    <form action="" method="post">
                                        <input type="email" name="email" />
                                        <input type="submit" value="Subscribe" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> */}

        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <NavHashLink
                  to="/"
                  className="logo d-flex align-items-center pull-left"
                >
                  <img
                    src="assets/img/Logo-New.png"
                    alt="ClinicianOS Logo"
                    style={{ marginBottom: "20px" }}
                  />
                </NavHashLink>
                <p>
                  226 Derby Road Nottingham NG71NQ <br />
                  <br />
                  <strong>Phone:</strong> +447770120105
                  <br />
                  <strong>Email:</strong> abid@clinicianos.com
                  <br />
                </p>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Contact Us</h4>
                <p>
  <b>Medical Enquiries:</b> <a href="mailto:abid@clinicianos.com">abid@clinicianos.com</a><br/>
  <b>Technical Enquiries:</b> <a href="mailto:vaibhav@clinicianos.com">vaibhav@clinicianos.com</a><br/>
  <b>Business Enquiries:</b> <a href="mailto:himanshu@clinicianos.com">himanshu@clinicianos.com</a><br/>
  <b>Internship/Work with us: </b><a href="mailto:hello@clinicianos.com">hello@clinicianos.com</a>
</p>

              </div>

              {/* <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="/home#welcome">Home</NavHashLink>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="/home#how-it-works">
                                            How does it work?
                                        </NavHashLink>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="/home#features">Features</NavHashLink>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="/home#why-moss-ai">
                                        Why ClinicianOS?
                                        </NavHashLink>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="/home#plans">Pricing</NavHashLink>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="#">How to use?</NavHashLink>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavHashLink to="/home#contact">Contact</NavHashLink>
                                        </li>
                                    </ul>
                                </div> */}

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Our Policy</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <NavLink to="/terms">Terms of Use</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <NavLink to="/privacy-policy">Privacy Statement</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <NavLink to="/cookie-policy">Cookie policy</NavLink>
                  </li>
                  {/* <li>
                                            <i className="bx bx-chevron-right"></i>{" "}
                                        <NavLink to="/faqs">FAQs</NavLink>
                                        </li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                {/* <h4>Our Affiliations</h4>
                                    <a
                                        href="https://www.escindia.in/membership-certifica/suzami-tech-pvt-ltd/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="logo"
                                        >
                                        <img
                                            src="assets/img/footer/footer-badge.png"
                                            alt="ESC's CERTIFIED MEMBER"
                                            style={{marginBottom: "20px"}}
                                            />
                                    </a>
                                    <a
                                        href="https://www.microsoft.com/en-in/startups"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="logo"
                                        >
                                        <img
                                            src="assets/img/footer/MS_startups_celebration_badge.png"
                                            alt="ESC's CERTIFIED MEMBER"
                                            style={{marginBottom: "20px", marginLeft: "10px"}}
                                            />
                                    </a> */}
                <h4>Our Social Networks</h4>
                <p>
                  We are present on major social media platforms, you can follow
                  us for more updates.
                </p>
                <div className="social-links mt-3">
                  {/* <a
                    href="https://youtube.com/"
                    className="youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-youtube"></i>
                  </a> */}
                  {/* <a
                                            href="https://twitter.com/sodiumna7"
                                            className="twitter"
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                            <i className="bx bxl-twitter"></i>
                                        </a>
                                        <a
                                            href="https://www.facebook.com/simplibot"
                                            className="facebook"
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                            <i className="bx bxl-facebook"></i>
                                        </a> */}
                  <a
                    href="https://www.instagram.com/clinician1uk/"
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/clinician-os/"
                    className="linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-4">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>ClinicianOS</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits"></div>
        </div>
      </footer>

      <NavLink
        to="/home"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </NavLink>
    </div>
  );
};
export default Footer;
