import React from "react";
const WhyMossAI = () => (
  <section id="why-moss-ai" className="details">
    <div className="container features">
      <div className="row content">
        <div
          className="content d-flex flex-column justify-content-center p-0"
          data-aos="fade-up"
        >
          <div className="row">
            <div className="section-title2 section-title">
              <h2>Benefits</h2>
            </div>
            <div
              className="col-md-4 icon-box aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-spreadsheet"></i>
              <h4>
                Let ClinicianOS handle your paperwork, record filing, and
                referral letters—lightening your load.
              </h4>
            </div>
            <div
              className="col-md-4 icon-box aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-task"></i>
              <h4>
                Engage with guidelines and research literature as if you're
                having a conversation.
              </h4>
            </div>
            <div
              className="col-md-4 icon-box aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bxl-graphql"></i>
              <h4>
                Connect with the community for case consultations, morbidity and
                mortality meet-up.
              </h4>
            </div>
            <div
              className="col-md-4 icon-box aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-group"></i>
              <h4>Manage your CME sessions seamlessly with ClinicianOS</h4>
            </div>

            <div
              className="col-md-4 icon-box aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-clinic"></i>
              <h4>Fewer administrative tasks → More time for patient care.</h4>
            </div>
            {/* <div  className="col-md-4 icon-box aos-init aos-animate" data-aos="fade-up">
                        <i class='bx bx-conversation' ></i>                        
                        <h4>
                            Capture <b>value from the beginning</b> of every conversation
                        </h4>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default WhyMossAI;
