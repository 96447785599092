/*
 *
 * App actions
 *
 */

import {
  INPUT_CHANGE,
  SUCCESS,
  FAILURE,
  FAILED_VALIDATION,
  CHECK_LOGIN_STATUS,
  LOGOUT,
  LOGIN,
  REGISTER,
  REQUEST_EMAIL_VERIFY,
  VERIFY,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  GET_PLAN,
  ADD_TO_CART,
  CHECKOUT,
  CANCEL_TRANS,
  PAYMENT_RESPONSE,
  GET_PROFILE,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  GET_TRANSACTION,
  GET_SUBSCRIPTION,
  GET_USAGE,
  GET_MY_MEETINGS,
  GET_SHARED_MEETINGS,
  RESET_FLOW,
  UPLOAD_MEETING,
  GET_MY_MEETING,
  RESET_SINGLE_MEETING,
  DOWNLOAD_FILE,
  UPDATE_MEETING,
  UPDATE_SPEAKER,
} from "./constants";

export function inputChange(formName, fieldName, value) {
  return {
    type: INPUT_CHANGE,
    payload: { formName, fieldName, value },
  };
}

export function success(
  actionName,
  resetForm = true,
  impactedFields = [],
  newValues = {},
  message = false
) {
  return {
    type: SUCCESS,
    payload: { actionName, resetForm, impactedFields, newValues, message },
  };
}

export function failure(
  actionName,
  error,
  impactedFields = [],
  newValues = {}
) {
  return {
    type: FAILURE,
    payload: { actionName, error, impactedFields, newValues },
  };
}

export function failedValidation(formName, error, validationResponse) {
  return {
    type: FAILED_VALIDATION,
    payload: { formName, error, validationResponse },
  };
}

export function checkLoginStatus() {
  return {
    type: CHECK_LOGIN_STATUS,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function login({ googleToken }) {
  return {
    type: LOGIN,
    payload: googleToken
  };
}

export function register(formFields) {
  return {
    type: REGISTER,
    payload: { formFields },
  };
}

export function requestEmailVerify(formFields) {
  return {
    type: REQUEST_EMAIL_VERIFY,
    payload: { formFields },
  };
}

export function verify(formFields) {
  return {
    type: VERIFY,
    payload: { formFields },
  };
}

export function forgotPassword(formFields) {
  return {
    type: FORGOT_PASSWORD,
    payload: { formFields },
  };
}

export function resetPassword(formFields) {
  return {
    type: RESET_PASSWORD,
    payload: { formFields },
  };
}

export function changePassword(formFields) {
  return {
    type: CHANGE_PASSWORD,
    payload: { formFields },
  };
}

export function getPlan() {
  return {
    type: GET_PLAN,
  };
}

export function addToCart(plan) {
  return {
    type: ADD_TO_CART,
    payload: { plan },
  };
}

export function checkOut() {
  return {
    type: CHECKOUT,
  };
}

export function cancelTrans() {
  return {
    type: CANCEL_TRANS,
  };
}

export function paymentResponse(gatewayResponse) {
  return {
    type: PAYMENT_RESPONSE,
    payload: { gatewayResponse },
  };
}

export function getProfile() {
  return {
    type: GET_PROFILE,
  };
}

export function updateProfile(formFields) {
  return {
    type: UPDATE_PROFILE,
    payload: { formFields },
  };
}

export function uploadMeeting(formFields) {
  return {
    type: UPLOAD_MEETING,
    payload: { formFields },
  };
}

export function deleteProfile() {
  return {
    type: DELETE_PROFILE,
  };
}

export function getTransaction() {
  return {
    type: GET_TRANSACTION,
  };
}

export function getSubscription() {
  return {
    type: GET_SUBSCRIPTION,
  };
}

export function getUsage() {
  return {
    type: GET_USAGE,
  };
}

export function getMyMeetings(action) {
  return {
    type: GET_MY_MEETINGS,
    query: action,
  };
}

export function getMyMeeting(meetingId) {
  return {
    type: GET_MY_MEETING,
    id: meetingId,
  };
}

export function getSharedMeetings() {
  return {
    type: GET_SHARED_MEETINGS,
  };
}
export function resetFlow() {
  return {
    type: RESET_FLOW,
  };
}
export function resetMeeting() {
  return {
    type: RESET_SINGLE_MEETING,
  };
}
export function updateMeeting(id, body) {
  return {
    type: UPDATE_MEETING,
    id,
    payload: body,
  };
}
export function updateSpeaker(id, body) {
  return {
    type: UPDATE_SPEAKER,
    id,
    payload: body,
  };
}
export function getDownloadFile(meetingId, meetingLanguage) {
  return {
    type: DOWNLOAD_FILE,
    id: meetingId,
    lang: meetingLanguage,
  };
}
