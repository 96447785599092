import React from "react";
const Features = () => (
  <section id="features" className="features">

    <div className="container">
      <div className="section-title" data-aos="fade-up">
        <h2>Features</h2>
        {/* <h3>Structured medical/clinical notes draft</h3>
            <p>
                Elevate your documentation process with Moss AI. Our application generates structured medical and clinical notes drafts, ensuring accuracy and efficiency in your practice.
            </p> */}
        <div
          class="content d-flex flex-column justify-content-center p-0 aos-init aos-animate"
          data-aos="fade-up"
        >
          <div className="d-flex gap-4 fwrap">
            <div
              class="col-md-4 icon-box1 aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-spreadsheet"></i>
              <p className="fs-18">
              Administrative Assistant: Automate note-taking, streamline writing referrals, and effortlessly manage your portfolio and logbook with the latest AI technology.{" "}
              </p>
            </div>
            <div
              class="col-md-4 icon-box1 aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-spreadsheet"></i>
              <p className="fs-18">
              AI-Enhanced Knowledge Hub: Instantly access and consume up-to-date medical guidelines, cutting-edge research literature, and essential clinical resources, all powered by multi modal Generative AI for real-time insights.{" "}
              </p>
            </div>
            <div
              class="col-md-4 icon-box1 aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="bx bx-spreadsheet"></i>
              <p className="fs-18">
              AI-Driven Continuous Medical Education (CME) Portal: Seamlessly access accredited courses, stay updated on the latest medical advancements, and fulfill professional development requirements with the efficiency and personalization enabled by Generative AI.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section-title" data-aos="fade-up">
                <h3>Supporting regional languages*</h3>
                <p className="row language-tiles">
                    <strong className="col">
                        <span>
                            A<sub>a</sub>
                        </span>
                        English
                    </strong>
                    <strong className="col">
                        <span>
                            अ<sub>आ</sub>
                        </span>
                        Hindi
                    </strong>
                    <strong className="col">
                        <span>
                            ळा
                            <sub>ळा</sub>
                        </span>
                        Marathi
                    </strong>
                    <strong className="col">
                        <span>
                            ಆ<sub>ಆ</sub>
                        </span>
                        Kannada
                    </strong>
                    <strong className="col">
                        <span>
                            અ<sub>અ</sub>
                        </span>
                        Gujarati
                    </strong>

                    <strong className="col">
                        <span>
                            এ<sub>এ</sub>
                        </span>
                        Bengali
                    </strong>
                    <strong className="col">
                        <span>
                            எ<sub>எ</sub>
                        </span>
                        Tamil
                    </strong>
                    <strong className="col">
                        <span>
                            ఆ<sub>ఆ</sub>
                        </span>
                        Telugu
                    </strong>

                </p>
                <small>*Transcript quality depends on audio quality</small>
            </div> */}
      <div className="section-title" data-aos="fade-up">
        <h2>Streamline Administrative Tasks with ClinicianOS</h2>
        <p className="fs-18">
        ClinicianOS is designed to take the burden of administrative tasks off your shoulders using our next Gen AI technology, so you can focus on patient care. With centralised task management and real-time updates, ClinicianOS helps clinicians save time, improve efficiency, and maintain better organisation across their practice.
        </p>
      </div>
      {/* <div className="section-title" data-aos="fade-up">
                <h3>Ground reality insights</h3>
                <p>
                    Gain invaluable insights with <span className="mossai-text">Moss.ai</span>. Whether it's through audio recordings or transcribed text, our application brings you closer to the ground reality of patient encounters, filling the gaps left by traditional practices and enhancing the quality of care provided.
                </p>
            </div> */}
    </div>
  </section>
);
export default Features;
