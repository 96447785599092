import React from "react";
import { Card } from "react-bootstrap";

const ClinicalNotes = ({ notes }) => {
  const sections = notes.split(
    /(?=Patient's History|Patient's Allergies|Differential Diagnosis)/
  );
  const PATIENTS_CHIEF_COMPLAINS = "Patient's Chief Complaints";
  const PATIENTS_HISTORY = "Patient's History";
  const PATIENTS_ALLERGIES = "Patient's Allergies";
  const DIFFERENTIAL_DIAGNOSIS = "Differential Diagnosis";

  const formatSections = (sec, heading) => {
    const parts = sec.split(heading);
    const formattedText = (
      <p className="text-black m-l-10">{parts[1].trim()}</p>
    );
    return (
      <Card.Text>
        {parts[0].trim()}
        {heading}: {formattedText}
      </Card.Text>
    );
  };

  const separateNotes = sections.map((section) => {
    if (section.includes(PATIENTS_CHIEF_COMPLAINS)) {
      return formatSections(section, PATIENTS_CHIEF_COMPLAINS);
    }
    if (section.includes(PATIENTS_HISTORY)) {
      return formatSections(section, PATIENTS_HISTORY);
    }
    if (section.includes(PATIENTS_ALLERGIES)) {
      return formatSections(section, PATIENTS_ALLERGIES);
    }
    if (section.includes(DIFFERENTIAL_DIAGNOSIS)) {
      return formatSections(section, DIFFERENTIAL_DIAGNOSIS);
    }
    return <p className="text-black">{section.trim() + "\n"}</p>;
  });

  return (
    <>
      {separateNotes.map((sections, index) => (
        <Card.Text key={index}>{sections}</Card.Text>
      ))}
    </>
  );
};

export default ClinicalNotes;
