import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";

import { makeSelectAppFormValue } from "../App/selectors";
import { inputChange, login, resetFlow } from "../App/actions";

import FormComponent from "../../components/FormComponent";
import { formFields } from "./internalConfig";

import { auth, provider } from "../../firebase"; // Import Firebase authentication
import { signInWithPopup } from "firebase/auth"; // Firebase auth method for Google login

const Login = ({
  isLogin,
  dLogin,
  dInputChange,
  formValue,
  isPageReady,
  location,
  dResetFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
  }, [isPageReady, location.pathname]);

  // Google login handler
  const handleGoogleLogin = async () => {
    try {
      // Trigger Google login popup
      const result = await signInWithPopup(auth, provider);

      // Get Firebase ID token (this is the token for authentication)
      const googleToken = await result.user.getIdToken(); // <-- This retrieves the Firebase ID token

      // Dispatch the login action with Google token only
      dLogin({ googleToken });
    } catch (error) {
      toast.error("Google login failed. Please try again.");
      console.error("Error during Google login:", error);
    }
  };

  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Login</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.login}
            formValid={{}}
            handleInputChange={dInputChange}
            submitLabel="Login"
            handleSubmit={dLogin}
          />
          {/* Google login button */}
          <div className="google-login mt-4">
            <button onClick={handleGoogleLogin} className="btn-google-login">
              <img
                src="https://clinicianos.com/assets/img/google.png"
                alt="Google Login"
                style={{ width: "30px", height: "auto" }}
              />
            </button>
          </div>
        </div>
        <div className="link-block">
          <NavLink to="/register">
            <strong>New to ClinicianOS?</strong>
            <span className="mossai-text"> Sign up now</span>
          </NavLink>
          or
          <NavLink to="/request-email-verify">
            <strong>Email Not Verified?</strong>
            <span className="mossai-text"> Verify Now</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
});

function mapDispatchToProps(dispatch) {
  return {
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dInputChange: (value, fieldName) => {
      if (fieldName === "email") {
        value = value.toLowerCase();
      }
      dispatch(inputChange("login", fieldName, value));
    },
    dLogin: (googleToken) => {
      dispatch(login({ googleToken })); // Pass the Google token to the login action
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Login);
