import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppFormComplete,
} from "../App/selectors";
import { inputChange, forgotPassword, resetFlow } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const ForgotPassword = ({
  isLogin,
  dForgotPassword,
  dInputChange,
  formValue,
  formValid,
  formComplete,
  isPageReady,
  location,
  dResetFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, location.pathname]);

  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }
  if (isPageReady && formComplete.FORGOT_PASSWORD) {
    return <Redirect to="/reset-password" />;
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Forgot Password</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.forgotPassword}
            formValid={formValid.forgotPassword}
            handleInputChange={dInputChange}
            submitLabel="Submit"
            handleSubmit={dForgotPassword}
          />
        </div>
        <div className="link-block">
          <NavLink to="/login">
            <strong>Remember Password?</strong>
            <span className="mossai-text"> Login</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
  formValid: makeSelectAppFormValid(),
  formComplete: makeSelectAppFormComplete(),
});

function mapDispatchToProps(dispatch) {
  return {
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dInputChange: (value, fieldName) => {
      if (fieldName === 'email') {
        value = value.toLowerCase();
      }
      dispatch(inputChange("forgotPassword", fieldName, value));
    },    
    dForgotPassword: () => {
      dispatch(forgotPassword(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ForgotPassword);
