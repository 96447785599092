import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";
import { cleanUp } from "../../utils/common";
import "./profile.css";

import {
  makeSelectAppTransactionHistory,
  makeSelectAppCurrentSubscription,
  makeSelectAppCurrentUsage,
  makeSelectAppMyMeetings,
  makeSelectAppSharedMeetings,
} from "../App/selectors";
import {
  getTransaction,
  getSubscription,
  getUsage,
  deleteProfile,
  getMyMeetings,
  getSharedMeetings,
  resetFlow,
  getDownloadFile,
} from "../App/actions";

import UserProfile from "../../components/UserProfile";
import MeetingsDetail from "../../components/MeetingsDetail";
import SubscriptionDetail from "../../components/SubscriptionDetail";
import TransactionHistory from "../../components/TransactionHistory";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import NewMeeting from "../../components/SubscriptionDetail/NewMeeting";
import NewDesignMeeting from "../../components/SubscriptionDetail/NewDesignMeeting";

const Profile = ({
  isLogin,
  userDetails,
  transactionHistory,
  currentSubscription,
  currentUsage,
  myMeetings,
  sharedMeetings,
  dGetUsage,
  dGetTransaction,
  dGetMyMeetings,
  dGetSubscription,
  dGetSharedMeetings,
  dDeleteProfile,
  isPageReady,
  location,
  dResetFlow,
  dGetDownloadFile,
}) => {
  var [transaction, SetTransaction] = useState(false);
  const [showNewMeeting, setShowMeeting] = useState(false);

  const handleClose = () => setShowMeeting(false);
  const handleShow = () => setShowMeeting(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
    var queryString = location.search.substring(1);
    if (queryString) {
      toast.success(cleanUp("Payment completed successfully"), {
        onClose: toast.dismiss(),
      });
      SetTransaction = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, location.pathname]);
  useEffect(() => {
    if (isPageReady && userDetails.id) {
      dGetSubscription();
      dGetTransaction();
      dGetMyMeetings();
      dGetSharedMeetings();
      dGetUsage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, userDetails.id, location.pathname]);
  if (isPageReady && !isLogin) {
    return <Redirect to="/login" />;
  }
  const tabTitle = (title) => {
    return (
      <div>
        <h5 className="text-decoration-none fw-bold text-black">{title}</h5>
      </div>
    );
  };
  return (
    <>
    <div className="profiletop">

    </div>
    {/* <div className="profile-header-background">
        <img src="assets/img/bg-01.jpg" alt="download moss.ai background" />
      </div> */}
      {/* <UserProfile userDetails={userDetails} deleteAccount={dDeleteProfile} /> */}
      {/* <NewDesignMeeting /> */}
      {/* <div className="container m-t-40">
        <Tab.Container defaultActiveKey="my_meetings">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="my_meetings">
                {tabTitle("My meetings")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="shared_with_me">
                {tabTitle("Shared with me")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="my_subscription">
                {tabTitle("My subscription")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item style={{ marginLeft: "25px" }}>
              <Button className="new-meeting ml-2" onClick={handleShow}>
                + Record/Upload new meeting
              </Button>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="my_meetings">
              <MeetingsDetail title="My " />
            </Tab.Pane>
            <Tab.Pane eventKey="shared_with_me">
              {sharedMeetings && !!sharedMeetings.length ? (
                <MeetingsDetail title="Shared " meetings={sharedMeetings} />
              ) : (
                <></>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="my_subscription">
              {currentSubscription && !!currentSubscription.length ? (
                <SubscriptionDetail
                  subscription={currentSubscription[0]}
                  currentUsage={currentUsage}
                />
              ) : (
                <></>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <div className="details">
          {transactionHistory && !!transactionHistory.length ? (
            <TransactionHistory transactionHistory={transactionHistory} />
          ) : (
            <></>
          )}
        </div>
        <Modal show={showNewMeeting} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-item-center">
              <i className="bx bx-mobile fs-30" />
            </div>
            <div className="d-flex justify-content-center new-meet-modal-title">
              Record/Upload new meeting
            </div>
            <NewMeeting />
          </Modal.Body>
        </Modal>
      </div> */}
      <div>
      <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSc2FYqNEjf9SAmgiIJw26IKSJZuKVcGfi6j4TCBjAITwvg9Wg/viewform?embedded=true"
          width="100%"
          height="2600px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  currentSubscription: makeSelectAppCurrentSubscription(),
  transactionHistory: makeSelectAppTransactionHistory(),
  currentUsage: makeSelectAppCurrentUsage(),
  myMeetings: makeSelectAppMyMeetings(),
  sharedMeetings: makeSelectAppSharedMeetings(),
});

function mapDispatchToProps(dispatch) {
  return {
    dGetTransaction: () => {
      dispatch(getTransaction());
    },
    dGetUsage: () => {
      dispatch(getUsage());
    },
    dGetSubscription: () => {
      dispatch(getSubscription());
    },
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dDeleteProfile: () => {
      dispatch(deleteProfile());
    },
    dGetMyMeetings: () => {
      dispatch(getMyMeetings());
    },
    dGetSharedMeetings: () => {
      dispatch(getSharedMeetings());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Profile);
