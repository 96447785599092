import React from "react";
const AboutUs = () => (
  <section id="aboutUs" className="aboutus">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>About Us</h2>
        {
          /* <h3>
          Honoring Our Dedicated{" "}
          <span className="mossai-text">Stakeholders and Mentors</span>
        </h3>*/
          <p className="mb-4 fs-18">
            Born in Nottingham, with our centre of excellence in Bangalore,
            ClinicianOS is reimagining healthcare from the ground up. Created by
            clinicians, technologists, and innovators from diverse fields and
            spanning multiple geographies, we understand the daily grind, the
            pressures, and the opportunities that modern medical practice
            brings. We are united by a common goal—to simplify the lives of
            clinicians worldwide.
          </p>
        }
        <h3 className="mt-4">Mission</h3>
        <p className="mb-4 fs-18">
          Our Mission is clear, streamline the admin, improve skills on the go,
          and chart professional growth-so healthcare professionals can focus on
          what really matters: delivering the kind of patient care they've
          always imagined.
        </p>
        <h3 className="mt-4">Vision</h3>
        <p className="mb-4 fs-18 ">
          A cooperative built by and for clinicians. To be accessible to every
          clinician, globally. We aim to create a platform for collaboration and
          become indispensable to both clinicians and patients.
        </p>
        <h3 className="mt-4">Values</h3>
        <p className="mb-4 fs-18">
          Clinician: Patients- first, always. <br/><br/>
          
          Strong partnerships with
          institutions, governments, and like-minded organizations. Committed to
          becoming a B Corp by 2027, balancing profit with purpose for a
          sustainable healthcare system.
        </p>
        <p className="mb-4 fs-18">
          At the heart of everything we do is a simple truth: better healthcare
          starts with empowered and unburdened clinicians. We're not just
          building tech-we're crafting a future where every clinician has the
          tools and support they need to thrive.
        </p>
      </div>
    </div>
  </section>
);
export default AboutUs;
