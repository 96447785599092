import React, { useEffect } from "react";

const Terms = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  return (
    <div className="container">
      <div className="row content m-t-100 p-t-20 p-b-20">
        <div className="col-12">
          <h1>Terms of Use</h1>
          <p>
            Welcome, and thank you for your interest in Clinician OS,
            (&ldquo;Clinician OS,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;) and
            our application, along with our website at Clinician OS, along with our
            related networks, applications, mobile applications, and other
            services provided by us (collectively, the &ldquo;Service&rdquo;).
            These Terms of Service are a legally binding contract between you
            and Clinician OS regarding your use of the Service. As used herein,
            &ldquo;you&rdquo; or &ldquo;your&rdquo; means any entity,
            university, organisation, or company accessing or using the Service
            (&ldquo;Organization&rdquo;) as well as any individual end user
            accessing and using the Service, as applicable and hereby agreeing
            to these Terms.
          </p>
          <h2>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</h2>
          <p>
            BY CLICKING &ldquo;I ACCEPT,&rdquo; OR BY DOWNLOADING, INSTALLING,
            OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE
            READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE,
            YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS,
            INCLUDING Clinician OS&rsquo;S PRIVACY STATEMENT AND COOKIE POLICY
            (TOGETHER, THESE &ldquo;TERMS''). IF YOU ARE NOT ELIGIBLE, OR DO NOT
            AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE
            SERVICE. YOUR USE OF THE SERVICE, AND Clinician OS&rsquo;S PROVISION OF
            THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY Clinician OS AND BY YOU
            TO BE BOUND BY THESE TERMS.{" "}
          </p>
          <h2>ARBITRATION NOTICE.</h2>
          <p>
            Except for certain kinds of disputes described in Section 19, you
            agree that disputes arising under these Terms will be resolved by
            binding, individual arbitration, and BY ACCEPTING THESE TERMS, YOU
            AND Clinician OS ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
            PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU
            AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your
            rights under this contract (except for matters that may be taken to
            small claims court). Your rights will be determined by a NEUTRAL
            ARBITRATOR and NOT a judge or jury. (See Section 19.)
          </p>
          <h3>1. Clinician OS Service Overview.</h3>
          <p>
            The Service enables users to transform recorded voice conversations
            into transcribed notes that can be searched and shared. You may
            choose whether to use the free version of the Service (&ldquo;Free
            Service&rdquo;) or the subscription-based paid version of the
            Service for which you may be required to pay fees (the &ldquo;Paid
            Service&rdquo;).
          </p>
          <h3>2. Eligibility.</h3>
          <p>
            You must be at least 13 years old to use the Service. By agreeing to
            these Terms, you represent and warrant to us that: (a) you are at
            least 13 years old; (b) you have not previously been suspended or
            removed from the Service; and (c) your registration and your use of
            the Service is in compliance with any and all applicable laws and
            regulations. If you are an Organization, the individual accepting
            these Terms on your behalf represents and warrants that they have
            authority to bind you to these Terms and you agree to be bound by
            these Terms.
          </p>
          <h3>3. Accounts and Registration.</h3>
          <h4>3.1 General.</h4>
          <p>
            To access most features of the Service, you must register for an
            account. When you register for an account, you may be required to
            provide us with some information about yourself, such as your name,
            email address, phone number, or other contact information. For
            Business or Enterprise Organizations, if you wish to designate
            individual users to access and use the Service under your account
            (such users, &ldquo;Authorised Users&rdquo;), you may also be
            required to provide us with some information about such Authorised
            Users. You agree that the information you provide to us is accurate
            and that you will keep it accurate and up-to-date at all times, and
            that you have all necessary rights and approvals to provide such
            information. When you register, you will be asked to provide a
            password. You are responsible for maintaining the confidentiality of
            your account and password, and you accept responsibility for all
            activities that occur under your account. If you believe that your
            account is no longer secure, then you must immediately notify us at
            abid@clinicianos.com.
          </p>
          <h4>3.2 Authorised Users.</h4>
          <p>
            a. You are responsible for: (i) identifying and authenticating all
            of your Authorised Users (ii) approving access by your Authorised
            Users to the Service and designating appropriate access controls in
            connection with your account, (iii) controlling against unauthorised
            access by your Authorised Users, and (iv) all activities that occur
            under your Authorised Users&rsquo; usernames, passwords or accounts
            as a result of your Authorised Users&rsquo; access and use of the
            Service. b. If you received your credentials to access and use the
            Service (including certain features thereof) from your Organisation
            or otherwise are accessing the Service under your
            Organization&rsquo;s account, you acknowledge and agree that your
            access to the Service as provided by your Organization is subject to
            the agreement between Clinician OS and your Organization with respect to
            the Service. You also acknowledge that your Organization may
            terminate the access to the Service that they provide to you at any
            time. Clinician OS will have no liability to you in connection with any
            such removal or termination. c. If the domain of the email address
            associated with your account is owned by an Organization and was
            assigned to you as an employee, contractor or member of the
            Organization (such as yourname@youremployer.com), you acknowledge
            and agree that we may identify your account to the Organization,
            migrate your account to the Organization&rsquo;s account, and/or
            restrict or terminate access to your account. Clinician OS will have no
            liability to you in connection with any such disclosure,
            restriction, or termination. d. When you join an Clinician OS Business or
            Enterprise account or when you convert an existing Clinician OS
            individual account into an Clinician OS Business or Enterprise account,
            you must do the following: -Use it in compliance with your
            organisation&rsquo;s terms and policies. Please note that Clinician OS
            Business and Enterprise accounts are subject to the
            Organization&rsquo;s control. The Organization administrators may
            prevent you from later disassociating your account from the
            Organization account. They may also restrict or terminate your
            access to a Clinician OS Business or Enterprise account. -Acknowledge
            that all the data under your account is owned by the Organization
            and the Organization administrators have the right to access,
            disclose, restrict and remove information in your account. This
            includes the data that predates when you joined the Organization
            account.
          </p>
          <h3>4. General Payment Terms.</h3>
          <p>
            Certain features of the Service may require you to pay fees. Before
            you pay any fees, you will have the opportunity to review and accept
            the fees that you will be charged. All fees are in INR and are
            non-refundable.
          </p>
          <h4>4.1 Price.</h4>
          <p>
            Clinician OS reserves the right to determine pricing for the Service.
            Clinician OS will make reasonable efforts to keep pricing information
            published on the website up to date. We encourage you to check our
            website periodically for current pricing information. Clinician OS may
            change the fees for any feature of the Service, including additional
            fees or charges, if Clinician OS gives you advance notice of changes
            before they apply. Clinician OS, at its sole discretion, may make
            promotional offers with different features and different pricing to
            any of Clinician OS&rsquo;s customers. These promotional offers, unless
            made to you, will not apply to your offer or these Terms.
          </p>
          <h4>4.2 Authorization.</h4>
          <p>
            You authorise Clinician OS and its third party payment processors to
            charge all sums for the orders that you make and any level of
            Service you select as described in these Terms or published by
            Clinician OS, including all applicable taxes, to the payment method
            specified in your account. If you pay any fees with a credit card,
            Clinician OS or its third party payment processors may seek
            pre-authorization of your credit card account prior to your purchase
            to verify that the credit card is valid and has the necessary funds
            or credit available to cover your purchase. At its discretion
            Clinician OS may allow an organisation to purchase the Service by issuing
            an invoice for payment that must be settled within 30 days of
            issuance.
          </p>
          <h4>4.3 Subscription Service.</h4>
          <h5>a. General.</h5>
          <p>
            The Service may include automatically recurring payments for
            periodic charges (&ldquo;Subscription Service&rdquo;). If you
            activate a Subscription Service, you authorise Clinician OS or its third
            party payment processors or Apple or Google if you subscribed
            directly through the mobile application to periodically charge, on a
            going-forward basis and until cancellation of either the recurring
            payments or your account, all accrued sums on or before the payment
            due date for the accrued sums, including all accrued sums for your
            Authorised Users. The &ldquo;Subscription Billing Date&rdquo; is the
            date when you purchase your first subscription to the Service. For
            more information on the &ldquo;Subscription Fee&rdquo;, please see
            https://clinicianos.com. Your account will be charged
            automatically on the Subscription Billing Date all applicable fees
            and taxes for the next subscription period, which may be one month
            or one year depending on the selection you make on your account
            (such period, the &ldquo;Initial Subscription Period&rdquo;). The
            term of each Subscription Service will automatically renew for for a
            period equal to the Initial Subscription Period or one (1) year
            periods, whichever is less, unless otherwise identified in an
            applicable Order Form (each, a &ldquo;Renewal Term&rdquo;) unless a
            party provides the other party written notice thirty (30) days in
            advance of the expiry of the Subscription Service or then-current
            Renewal Term, as applicable, of its desire to amend the duration of
            the Renewal Term, modify number of Users or terminate the Order
            Form. Customer is responsible and will pay Clinician OS all Subscription
            Fees under an Order Form for the entire Subscription Term and any
            applicable Renewal Term. If you may add additional users to Teams
            accounts throughout the Subscription Period you will be charged a
            pro-rata fee for the time remaining to the renewal period. You must
            cancel your subscription before it renews in order to avoid billing
            of the next periodic Subscription Fee to your account. Clinician OS or
            its third party payment processor will bill the periodic
            Subscription Fee to the payment method you provide to us during
            registration (or to a different payment method if you change your
            payment information). You may cancel the Subscription Service in the
            settings page for your account at Clinician OS if you signed up directly
            via our website, or through settings in the Apple App Store, or
            Google Play Store if you subscribed directly in the mobile
            application or contacting us at: abid@clinicianos.com.
          </p>
          <h4>4.4 Delinquent Accounts.</h4>
          <p>
            Clinician OS may suspend or terminate access to the Service, including
            fee-based portions of the Service, for any account for which any
            sums are due but unpaid. Without limiting the generality of the
            foregoing, if you have been provided access to the Service
            (including certain features thereof) via your Organization and your
            Organisation has not paid all sums due, we may suspend or terminate
            your access to the Service. In addition to the amount due for the
            Service, a delinquent account may be charged with fees or charges
            that are incidental to any chargeback or collection of any unpaid
            amount, including collection fees.
          </p>
          <h3>5. Licences</h3>
          <h4>5.1 Limited License.</h4>
          <p>
            Subject to your complete and ongoing compliance with these Terms,
            Clinician OS grants you, solely for your personal, non-commercial use, a
            limited, non-exclusive, non-transferable, non-sublicensable,
            revocable licence to: (a) install and use one object code copy of
            any mobile application associated with the Service obtained from a
            legitimate marketplace on a mobile device that you own or control;
            and (b) access and use the Service.
          </p>
          <h4>5.2 License Restrictions.</h4>
          <p>
            Except and solely to the extent such a restriction is impermissible
            under applicable law, you may not: (a) reproduce, distribute,
            publicly display, or publicly perform the Service; (b) make
            modifications to the Service; (c) interfere with or circumvent any
            feature of the Service, including any security or access control
            mechanism; (d) access or use the Service in violation of any usage
            restrictions or other limitations associated with the level of
            Service you (or your Organization) have selected to access and
            purchased, if applicable. If you are prohibited under applicable law
            from using the Service, you may not use it.
          </p>
          <h4>5.3 Feedback.</h4>
          <p>
            If you choose to provide input and suggestions regarding problems
            with or proposed modifications or improvements to the Service
            (&ldquo;Feedback&rdquo;), then you hereby grant Clinician OS an
            unrestricted, perpetual, irrevocable, non-exclusive, fully-paid,
            royalty-free right to exploit the Feedback in any manner and for any
            purpose, including to improve the Service and create other products
            and services.
          </p>
          <h3>6. Ownership; Proprietary Rights.</h3>
          <p>
            The Service is owned and operated by Clinician OS. The visual interfaces,
            graphics, design, compilation, information, data, computer code
            (including source code or object code), products, software,
            services, and all other elements of the Service
            (&ldquo;Materials&rdquo;) provided by Clinician OS are protected by
            intellectual property and other laws. All Materials included in the
            Service are the property of Clinician OS or its third party licensors.
            Except as expressly authorised by Clinician OS, you may not make use of
            the Materials. Clinician OS reserves all rights to the Materials not
            granted expressly in these Terms.
          </p>
          <h3>7. Government Rights.</h3>
          <p>
            Clinician OS provides the Service, including any related software, data,
            and technology, for ultimate government end use solely in accordance
            with the following: The government hereby agrees that the Service
            qualifies as &ldquo;commercial&rdquo; computer software. Government
            technical data and software rights related to the Service include
            only those rights customarily provided to the public as defined in
            these Terms. If a government agency has a need for rights not
            granted under these Terms, it must negotiate with Clinician OS to
            determine if there are acceptable terms for granting those rights,
            and a mutually acceptable written addendum specifically granting
            those rights must be included in any applicable agreement.
          </p>
          <h3>8. Third Party Terms</h3>
          <h4>8.1 Third Party Services and Linked Websites.</h4>
          <p>
            Clinician OS may provide tools through the Service that enable you to
            export information, including User Content, to third party services,
            including through features that may allow you to link your account
            on Clinician OS with an account on the third party service, such as
            Twitter or Facebook, or through our implementation of third party
            buttons (such as &ldquo;like&rdquo; or &ldquo;share&rdquo; buttons).
            By using one of these tools, you agree that Clinician OS may transfer
            that information to the applicable third party service. Third party
            services are not under Clinician OS&rsquo;s control, and, to the fullest
            extent permitted by law, Clinician OS is not responsible for any third
            party service&rsquo;s use of your exported information. The Service
            may also contain links to third party websites. Linked websites are
            not under Clinician OS&rsquo;s control, and Clinician OS is not responsible
            for their content.
          </p>
          <h3>9. User Content</h3>
          <h4>9.1 User Content Generally.</h4>
          <p>
            Certain features of the Service may permit users to upload content
            to the Service (including by syncing your account with Third Party
            Accounts as further described in Section 9.1), including voice
            recordings, audio recordings, data, text, photographs, and other
            types of works (&ldquo;User Content&rdquo;), and to otherwise
            publish User Content on the Service. To the extent you desire to use
            the Service in connection with materials or information located on
            your Zoom, Dropbox, or other third-party accounts (collectively,
            &ldquo;Third Party Accounts&rdquo;), you hereby grant Clinician OS
            permission to access the Third Party Accounts in connection with
            Clinician OS&rsquo;s provision of the Service. As between you and
            Clinician OS, you retain any copyright and other proprietary rights that
            you may hold in the User Content that you post to the Service.{" "}
          </p>
          <h4>9.2 Voice Recordings.</h4>
          <p>
            The Service may provide a feature that allows you to record
            individual conversations and/or upload recorded conversations. The
            laws regarding the notice and notification requirements of such
            recorded conversations vary by location. You acknowledge and agree
            that you are solely responsible for providing any notices to, and
            obtaining consent from, individuals in connection with any
            recordings as required under applicable law.
          </p>
          <h4>9.3 Limited License Grant to Clinician OS.</h4>
          <p>
            Customer retains all ownership rights to the User Content processed
            using the service. You grant Clinician OS a worldwide, non-exclusive,
            royalty-free, fully paid right and licence (with the right to
            sublicense) to host, store, transfer, display, perform, reproduce,
            modify, export, process, transform, and distribute your User
            Content, in whole or in part, in any media formats and through any
            media channels now known or hereafter developed in a manner that is
            under your control. Customers may delete User Content from their
            account where it may be stored in a separate trash folder provided
            with the service for a specified period unless they choose to delete
            it from the trash folder. Once it has been permanently deleted from
            the user account either by direct action by the user or on
            expiration of the specified retention period in the trash folder, no
            record of the User Content is retained and the User Content cannot
            be recreated by the service.{" "}
          </p>
          <h4>9.4 License Grant to Users.</h4>
          <p>
            By providing User Content to or via the Service to other users of
            the Service, you grant those users a non-exclusive licence to
            access, use, modify, and distribute that User Content as permitted
            by these Terms and the functionality of the Service.{" "}
          </p>
          <h4>9.5 Access to User Content and Results.</h4>
          <p>
            Clinician OS may permit you to share certain User Content or the results
            of processing User Content with other users of the Service, share
            User Content or the results of processing User Content outside of
            the Service, or even make certain User Content or the results of
            processing User Content public for all (even non-Service users) to
            view. You acknowledge and agree that, although Clinician OS may provide
            certain features intended to allow you to restrict access of some
            User Content you create from others, Clinician OS does not guarantee that
            such User Content or any results of processing User Content will
            never be accessible by others. To the fullest extent permitted by
            law, Clinician OS is not responsible for the use of any User Content or
            results of processing User Content by users or non-users of the
            Service or any third parties.{" "}
          </p>
          <h4> 9.6 User Content Representations and Warranties.</h4>
          <p>
            Clinician OS disclaims any and all liability in connection with User
            Content. You are solely responsible for your User Content and the
            consequences of providing User Content via the Service. By providing
            User Content via the Service, you affirm, represent, and warrant
            that: a. you are the creator and owner of the User Content, or have
            the necessary licences, rights, consents, and permissions to
            authorise Clinician OS and users of the Service to use and distribute
            your User Content as necessary to exercise the licences granted by
            you in this Section, in the manner contemplated by Clinician OS, the
            Service, and these Terms; b. your User Content, and the use of your
            User Content as contemplated by these Terms, does not and will not:
            (i) infringe, violate, or misappropriate any third party right,
            including any copyright, trademark, patent, trade secret, moral
            right, privacy right, right of publicity, or any other intellectual
            property or proprietary right; (ii) slander, defame, libel, or
            invade the right of privacy, publicity or other property rights of
            any other person; or (iii) cause Clinician OS to violate any law or
            regulation; and c. your User Content could not be deemed by a
            reasonable person to be objectionable, profane, indecent,
            pornographic, harassing, threatening, embarrassing, hateful, or
            otherwise inappropriate.{" "}
          </p>
          <h4>9.7 User Content Disclaimer.</h4>
          <p>
            We are under no obligation to edit or control User Content that you
            or other users post or publish, and will not be in any way
            responsible or liable for User Content. Clinician OS may, however, at any
            time and without prior notice, screen, remove, edit, or block any
            User Content that in our sole judgement violates these Terms or is
            otherwise objectionable. You understand that when using the Service
            you will be exposed to User Content from a variety of sources and
            acknowledge that User Content may be inaccurate, offensive,
            indecent, or objectionable. You agree to waive, and do waive, any
            legal or equitable right or remedy you have or may have against
            Clinician OS with respect to User Content. If notified by a user or
            content owner that User Content allegedly does not conform to these
            Terms, we may investigate the allegation and determine in our sole
            discretion whether to remove the User Content, which we reserve the
            right to do at any time and without notice. For clarity, Clinician OS
            does not permit copyright-infringing activities on the Service.
          </p>
          <h4>9.8 Monitoring Content.</h4>
          <p>
            Clinician OS does not control and does not have any obligation to
            monitor: (a) User Content; (b) any content made available by third
            parties; or (c) the use of the Service by its users. Clinician OS is not
            responsible for the use of any User Content by users or any third
            parties. You acknowledge and agree that Clinician OS reserves the right
            to, and may from time to time, monitor any and all information
            transmitted or received through the Service for operational and
            other purposes. If at any time Clinician OS chooses to monitor the
            content, Clinician OS still assumes no responsibility or liability for
            content or any loss or damage incurred as a result of the use of
            content.
          </p>
          <h4>9.9 Machine Learning.</h4>
          <p>
            Clinician OS shall have the right to collect and analyse data and other
            information relating to the provision, use and performance of
            various aspects of the Service and related systems and technologies
            (&ldquo;Usage Data&rdquo;). The Service may be implemented using
            machine learning systems with features and implementations designed
            to generate statistics, calibrate data models, and improve
            algorithms in the course of processing User Content and Usage Data
            (&ldquo;Machine Learning&rdquo;). Nothing in these Terms prohibits
            the Company from using such Machine Learning for testing, tuning,
            optimising, validating, or otherwise enhancing the analytics,
            models, or algorithms underlying the Service. Nothing in these Terms
            gives you any rights in or to any part of the Service or the Machine
            Learning generated by Company or the Machine Learning generated in
            the course of providing the Service.
          </p>
          <h3>10. Communications.</h3>
          <h4>10.1 Text Messaging.</h4>
          <p>
            {" "}
            Clinician OS and those acting on our behalf may send you text (SMS)
            messages at the phone number you provide us. These messages may be
            used for two factor authentication. Standard data and message rates
            may apply whenever you send or receive such messages, as specified
            by your carrier and Clinician OS is not responsible for these charges.
          </p>
          <h4>10.2 Push Notifications.</h4>
          <p>
            When you install our app on your mobile device, you agree to receive
            push notifications, which are messages an app sends you on your
            mobile device when the app is not on. You can turn off notifications
            by visiting your mobile device&rsquo;s &ldquo;settings&rdquo; page.
          </p>
          <h4>10.3 Email.</h4>
          <p>
            We may send you emails in the course of delivering the service to
            notify when User Content has been processed, shared with you or for
            other operational purposes. We may also send emails concerning our
            products and services, as well as those of third parties. You may
            opt out of promotional emails by following the unsubscribe
            instructions in the promotional email itself.
          </p>
          <h3>11. Prohibited Conduct. </h3>
          <p>
            BY USING THE SERVICE YOU AGREE NOT TO: a. use the Service for any
            illegal purpose or in violation of any local, state, national, or
            international law; b. use the Service or any portion thereof for the
            direct or indirect benefit of any third parties; c. use the Service
            in connection with any direct or indirect commercial purposes,
            including in connection with any paid transcription workflow or as a
            value-added component of a commercial product or service; d. harass,
            threaten, demean, embarrass, or otherwise harm any other user of the
            Service; e. violate, or encourage others to violate, any right of a
            third party (including by act or omission), including by infringing
            or misappropriating any third party intellectual property or
            proprietary right; f. interfere with security-related features of
            the Service, including by: (i) disabling or circumventing features
            that prevent or limit use or copying of any content; or (ii) reverse
            engineering or otherwise attempting to discover the source code of
            any portion of the Service except to the extent that the activity is
            expressly permitted by applicable law; g. interfere with the
            operation of the Service or any user&rsquo;s enjoyment of the
            Service, including by: (i) uploading or otherwise disseminating any
            virus, adware, spyware, worm, or other malicious code; (ii) making
            any unsolicited offer or advertisement to another user of the
            Service; (iii) collecting personal information about another user or
            third party without consent; or (iv) interfering with or disrupting
            any network, equipment, or server connected to or used to provide
            the Service; h. perform any fraudulent activity including
            impersonating any person or entity, claiming a false affiliation,
            accessing any other Service account without permission, or
            falsifying your age or date of birth; i. sell or otherwise transfer
            the access granted under these Terms or any Materials (as defined in
            Section 6) or any right or ability to view, access, or use any
            Materials; or j. attempt to do any of the acts described in this
            Section 11 or assist or permit any person in engaging in any of the
            acts described in this Section 11.
          </p>
          <h3>12. Digital Millennium Copyright Act</h3>
          <h4>12.1 DMCA Notification.</h4>
          <p>
            We comply with the provisions of the Digital Millennium Copyright
            Act applicable to Internet service providers (17 U.S.C. &sect;512,
            as amended). If you have an intellectual property rights-related
            complaint about material posted on the Service, you may contact our
            Designated Agent at the following address: Clinician OS,
            ATTN: Legal Department (Copyright Notification) 226 Derby Road Nottingham NG71NQ
            Email: abid@clinicianos.com Any notice alleging that
            materials hosted by or distributed through the Service infringe
            intellectual property rights must include the following information:
            a. an electronic or physical signature of the person authorised to
            act on behalf of the owner of the copyright or other right being
            infringed; b. a description of the copyrighted work or other
            intellectual property that you claim has been infringed; c. a
            description of the material that you claim is infringing and where
            it is located on the Service; d. your address, telephone number, and
            email address; e. a statement by you that you have a good faith
            belief that the use of the materials on the Service of which you are
            complaining is not authorised by the copyright owner, its agent, or
            the law; and f. a statement by you that the above information in
            your notice is accurate and that, under penalty of perjury, you are
            the copyright or intellectual property owner or authorised to act on
            the copyright or intellectual property owner&rsquo;s behalf.
          </p>
          <h4>12.2 Repeat Infringers.</h4>
          <p>
            Clinician OS will promptly terminate the accounts of users that are
            determined by Clinician OS to be repeat infringers.
          </p>
          <h3>13. Modification of these Terms.</h3>
          <p>
            We reserve the right to change these Terms on a going-forward basis
            at any time. Please check these Terms periodically for changes. If a
            change to these Terms materially modifies your rights or
            obligations, we may require that you accept the modified Terms in
            order to continue to use the Service. Material modifications are
            effective upon your acceptance of the modified Terms. Immaterial
            modifications are effective upon publication. Except as expressly
            permitted in this Section 13, these Terms may be amended only by a
            written agreement signed by authorised representatives of the
            parties to these Terms. Disputes arising under these Terms will be
            resolved in accordance with the version of these Terms that was in
            effect at the time the dispute arose.
          </p>
          <h3>14. Term, Termination and Modification of the Service</h3>
          <h4>14.1 Term.</h4>
          <p>
            These Terms are effective beginning when you accept the Terms or
            first download, install, access, or use the Service, and ending when
            terminated as described in Section 14.2.{" "}
          </p>
          <h4>14.2 Termination.</h4>
          <p>
            If you violate any provision of these Terms, your authorization to
            access the Service and these Terms automatically terminate. In
            addition, Clinician OS may, at its sole discretion, terminate these Terms
            or your account on the Service, or suspend or terminate your access
            to the Service, at any time for any reason or no reason, with or
            without notice. You may terminate your account and these Terms at
            any time by contacting customer service at abid@clinicianos.com (ATTN:
            Account Termination) or through the mobile application by going to
            user profile.{" "}
          </p>
          <h4>14.3 Effect of Termination.</h4>
          <p>
            Upon termination of these Terms: (a) your licence rights will
            terminate and you must immediately cease all use of the Service; (b)
            you will no longer be authorised to access your account or the
            Service; (c) you must pay Clinician OS any unpaid amount that was due
            prior to termination; and (d) all payment obligations accrued prior
            to termination and Sections [5.3, 6, 7, 10, 14.3, 16, 16, 17, 18,
            19, and 20] will survive. 14.4 Modification of the Service. Clinician OS
            reserves the right to modify or discontinue the Service at any time
            (including by limiting or discontinuing certain features of the
            Service), temporarily or permanently, without notice to you. Clinician OS
            will have no liability for any change to the Service or any
            suspension or termination of your access to or use of the Service.{" "}
          </p>
          <h3>15. Indemnity.</h3>
          <p>
            To the fullest extent permitted by law, you are responsible for your
            use of the Service, and you will defend and indemnify Clinician OS and
            its officers, directors, employees, consultants, affiliates,
            subsidiaries and agents (together, the &ldquo;Clinician OS
            Entities&rdquo;) from and against every claim brought by a third
            party, and any related liability, damage, loss, and expense,
            including reasonable attorneys&rsquo; fees and costs, arising out of
            or connected with: (a) your unauthorised use of, or misuse of, the
            Service; (b) your violation of any portion of these Terms, any
            representation, warranty, or agreement referenced in these Terms, or
            any applicable law or regulation; (c) your violation of any third
            party right, including any intellectual property right or publicity,
            confidentiality, other property, or privacy right; (d) the nature of
            content of Data processed by the Service; or (e) any dispute or
            issue between you and any third party. We reserve the right, at our
            own expense, to assume the exclusive defence and control of any
            matter otherwise subject to indemnification by you (without limiting
            your indemnification obligations with respect to that matter), and
            in that case, you agree to cooperate with our defence of those
            claims.{" "}
          </p>
          <h3>16. Disclaimers; No Warranties</h3>
          <p>
            THE SERVICE AND ALL MATERIALS AND CONTENT AND TRANSCRIPTIONS
            AVAILABLE THROUGH THE SERVICE ARE PROVIDED &ldquo;AS IS'' AND ON AN
            &ldquo;AS AVAILABLE&rdquo; BASIS. Clinician OS DISCLAIMS ALL WARRANTIES
            OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND
            ALL MATERIALS AND CONTENT AND TRANSCRIPTIONS AVAILABLE THROUGH THE
            SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
            NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF
            DEALING, USAGE, OR TRADE. Clinician OS DOES NOT WARRANT THAT THE SERVICE
            OR ANY PORTION OF THE SERVICE (OR YOUR ACCESS THERETO), OR ANY DATA,
            MATERIALS OR CONTENT OFFERED THROUGH OR PROCESSED BY THE SERVICE,
            WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER
            HARMFUL COMPONENTS, AND Clinician OS DOES NOT WARRANT THAT ANY OF THOSE
            ISSUES WILL BE CORRECTED. Clinician OS IS NOT RESPONSIBLE FOR THE FAILURE
            TO STORE OR MAINTAIN ANY USER DATA, CONTENT OR TRANSCRIPTIONS, USER
            COMMUNICATIONS, ACCOUNT INFORMATION, OR PERSONAL SETTINGS. Clinician OS
            MAKES NO WARRANTY ABOUT THE COMPLETENESS OR ACCURACY OF THE
            TRANSCRIPTION. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
            OBTAINED BY YOU FROM THE SERVICE OR Clinician OS ENTITIES OR ANY
            MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY
            WARRANTY REGARDING ANY OF THE Clinician OS ENTITIES OR THE SERVICE THAT
            IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR
            ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH
            ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY
            PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE
            ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
            COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE
            SERVICE) OR ANY LOSS OF, USE OR DISCLOSURE OF DATA, INCLUDING USER
            CONTENT. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION
            APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
          </p>
          <p>
            Clinician OS does not disclaim any warranty or other right that Clinician OS
            is prohibited from disclaiming under applicable law.
          </p>
          <h3>17. Limitation of Liability</h3>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE Clinician OS
            ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
            RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR
            USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
            BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE,
            OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY Clinician OS ENTITY HAS
            BEEN INFORMED OF THE POSSIBILITY OF DAMAGE. EXCEPT AS PROVIDED IN
            SECTION 19.5 AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE
            AGGREGATE LIABILITY OF THE Clinician OS ENTITIES TO YOU FOR ALL CLAIMS
            ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY
            PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN
            CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE
            AMOUNT YOU HAVE PAID TO Clinician OS FOR ACCESS TO AND USE OF THE SERVICE
            IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO
            CLAIM; OR (B) $100. EACH PROVISION OF THESE TERMS THAT PROVIDES FOR
            A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
            DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE
            PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT
            OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
            PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF
            THESE TERMS. THE LIMITATIONS IN THIS SECTION 17 WILL APPLY EVEN IF
            ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </p>
          <h3>18. Privacy and Information Security</h3>
          <h4>18.1 Privacy Policy.</h4>
          <p>
            You acknowledge and agree that except as described in these Terms,
            any User Content, data, recordings, and information you enter into
            or upload to the Service or that we collect in connection with the
            Service (&ldquo;Data&rdquo;) will be processed as described in the
            Clinician OS Privacy Statement and Cookie Policy. Please read the Privacy
            Statement and Cookie Policy carefully.
          </p>
          <h4>18.2 Data Processing.</h4>
          <p>
            To the extent Data falls within the scope of the General Data
            Protection Regulation or the United Kingdom General Data Protection
            Regulation, the terms of the Data Processing Attachment in Appendix
            1 of these Terms (&ldquo;DPA&rdquo;) apply to the processing of any
            Customer Personal Data (as defined in the DPA). To the extent Data
            falls within the scope of any U.S. state privacy laws or their
            implementing regulations, the terms of the U.S. State Data
            Processing Agreement in Appendix 6 (&ldquo;U.S. State DPA&rdquo;)
            apply to the processing of any Personal Data (as defined in the U.S.
            State DPA).{" "}
          </p>
          <h4>18.3 Data.</h4>
          <p>
            As between you and Clinician OS, data that you enter into or upload to
            the Service is and will remain owned by you. You hereby grant
            Clinician OS the right to collect, process, transmit, store, use, and
            disclose data to provide the Service and as otherwise set forth in
            these Terms and the Clinician OS Privacy Statement and Cookie Policy.
            When you join a Business or Enterprise Organization, your data is
            owned by the Organization as specified in section 3.2 above.
          </p>
          <h4>18.4 Use of Aggregated Data.</h4>
          <p>
            You acknowledge and agree that Clinician OS may collect, create, process,
            transmit, store, use, and disclose aggregated and/or de identified
            data derived from Data or use of the Services (&ldquo;Aggregated
            Data&rdquo;) for its business purposes, including for machine
            learning and training, industry analysis, benchmarking, and
            analytics. All Aggregated Data will be in an aggregated and/or de
            identified form only and will not identify you. Nothing in these
            Terms gives you any rights in or to any part of the Service or
            Aggregated Data.
          </p>
          <h4>18.5 Compliance.</h4>
          <p>
            You are solely responsible (a) for Data as entered into, supplied,
            accessed, or used by you and (b) for complying with any privacy and
            data protection laws and regulations applicable to Data or your use
            of the Service. You represent and warrant that you have obtained and
            will maintain all rights, consents, and authorizations required to
            grant Clinician OS the rights and licences set forth in Section 18 and to
            enable Clinician OS to exercise its rights under the same without
            violation or infringement of the rights of any third party.{" "}
          </p>
          <h4>18.6 Information Security.</h4>
          <p>
            Clinician OS will employ commercially reasonable security measures that
            are designed to protect Data in its possession or control against
            unlawful or unauthorised access, use, alteration, or disclosure.{" "}
          </p>
          <h3>19. Dispute Resolution and Arbitration</h3>
          <p>
            <h4>19.1 Generally.</h4>
          </p>
          <p>
            In the interest of resolving disputes between you and Clinician OS in the
            most expedient and cost effective manner, and except as described in
            Section 19.2 and 19.3, you and Clinician OS agree that every dispute
            arising in connection with these Terms will be resolved by binding
            arbitration. Arbitration is less formal than a lawsuit in court.
            Arbitration uses a neutral arbitrator instead of a judge or jury,
            may allow for more limited discovery than in court, and can be
            subject to very limited review by courts. Arbitrators can award the
            same damages and relief that a court can award. This agreement to
            arbitrate disputes includes all claims arising out of or relating to
            any aspect of these Terms, whether based in contract, tort, statute,
            fraud, misrepresentation, or any other legal theory, and regardless
            of whether a claim arises during or after the termination of these
            Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS,
            YOU AND Clinician OS ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
            PARTICIPATE IN A CLASS ACTION.
          </p>
          <h4>19.2 Exceptions.</h4>
          <p>
            Despite the provisions of Section 19.1, nothing in these Terms will
            be deemed to waive, preclude, or otherwise limit the right of either
            party to: (a) bring an individual action in small claims court; (b)
            pursue an enforcement action through the applicable federal, state,
            or local agency if that action is available; (c) seek injunctive
            relief in a court of law in aid of arbitration; or (d) to file suit
            in a court of law to address an intellectual property infringement
            claim.{" "}
          </p>
          <h4>19.3 Opt-Out.</h4>
          <p>
            If you do not wish to resolve disputes by binding arbitration, you
            may opt out of the provisions of this Section 19 within 30 days
            after the date that you agree to these Terms by sending a letter to
            Clinician OS, Clinician OS, ATTN: Legal Department -Arbitration
            Opt-Out, 226 Derby Road Nottingham NG71NQ that specifies: your full legal name,
            the email address associated with your account on the Service, and a
            statement that you wish to opt out of arbitration (&ldquo;Opt-Out
            Notice&rdquo;). Once Clinician OS receives your Opt-Out Notice, this
            Section 19 will be void and any action arising out of these Terms
            will be resolved as set forth in Section 20.2. The remaining
            provisions of these Terms will not be affected by your Opt-Out
            Notice.
          </p>
          <h4>19.4 Notice of Arbitration; Process.</h4>
          <p>
            A party who intends to seek arbitration must first send a written
            notice of the dispute to the other party by certified India Post
            (signature required) or, only if that other party has not provided a
            current physical address, then by electronic mail (&ldquo;Notice of
            Arbitration&rdquo;). Clinician OS&rsquo;s address for Notice is:
            abid@clinicianos.com. The Notice of Arbitration must: (a) describe
            the nature and basis of the claim or dispute; and (b) set forth the
            specific relief sought (&ldquo;Demand&rdquo;). The parties will make
            good faith efforts to resolve the claim directly, but if the parties
            do not reach an agreement to do so within 30 days after the Notice
            of Arbitration is received, you or Clinician OS may commence an
            arbitration proceeding. All arbitration proceedings between the
            parties will be confidential unless otherwise agreed by the parties
            in writing. During the arbitration, the amount of any settlement
            offer made by you or Clinician OS must not be disclosed to the arbitrator
            until after the arbitrator makes a final decision and award, if any.
            If the arbitrator awards you an amount higher than the last written
            settlement amount offered by Clinician OS in settlement of the dispute
            prior to the award, Clinician OS will pay you the higher of: (i) the
            amount awarded by the arbitrator; or (ii) $10,000.{" "}
          </p>
          <h4>19.5 No Class Actions.</h4>
          <p>
            YOU AND Clinician OS AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
            ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
            Further, unless both you and Clinician OS agree otherwise, the arbitrator
            may not consolidate more than one person&rsquo;s claims, and may not
            otherwise preside over any form of a representative or class
            proceeding.
          </p>
          <h4>19.6 Modifications to this Arbitration Provision.</h4>
          <p>
            If Clinician OS makes any future change to this arbitration provision,
            other than a change to Clinician OS&rsquo;s address for Notice of
            Arbitration, you may reject the change by sending us written notice
            within 30 days of the change to Clinician OS&rsquo;s address for Notice
            of Arbitration, in which case your account with Clinician OS will be
            immediately terminated and this arbitration provision, as in effect
            immediately prior to the changes you rejected will survive.
          </p>
          <h4>19.7 Enforceability.</h4>
          <p>
            If Section 19.7 or the entirety of this Section 19 is found to be
            unenforceable, or if Clinician OS receives an Opt-Out Notice from you,
            then the entirety of this Section 19 will be null and void and, in
            that case, exclusive jurisdiction and venue described in Section
            20.2 will govern any action arising out of or related to these
            Terms.
          </p>
          <h3>20. Miscellaneous</h3>
          <h4>20.1 General Terms.</h4>
          <p>
            These Terms, together with the Privacy Statement and Cookie Policy
            and any other agreements expressly incorporated by reference into
            these Terms, are the entire and exclusive understanding and
            agreement between you and Clinician OS regarding your use of the Service.
            You may not assign or transfer these Terms or your rights under
            these Terms, in whole or in part, by operation of law or otherwise,
            without our prior written consent. We may assign these Terms at any
            time without notice or consent. The failure to require performance
            of any provision will not affect our right to require performance at
            any other time after that, nor will a waiver by us of any breach or
            default of these Terms, or any provision of these Terms, be a waiver
            of any subsequent breach or default or a waiver of the provision
            itself. Use of section headers in these Terms is for convenience
            only and will not have any impact on the interpretation of any
            provision. Throughout these Terms the use of the word
            &ldquo;including&rdquo; means &ldquo;including but not limited
            to&rdquo;. If any part of these Terms is held to be invalid or
            unenforceable, the unenforceable part will be given effect to the
            greatest extent possible, and the remaining parts will remain in
            full force and effect.{" "}
          </p>
          <h4>20.2 Governing Law.</h4>
          <p>
            If the representatives are not able to resolve the disputes within
            thirty (30) days after the dispute is referred to them, all such
            disputes will be settled by arbitration in accordance with the
            Indian Arbitration and Conciliation Act, 1996 or any statutory
            modification or enactment thereof. c. This Agreement shall be
            governed by laws of India and courts at Bangalore shall have
            exclusive jurisdiction pertaining to this Agreement. The proceedings
            of the Arbitration shall be conducted in English language and the
            place of hearing shall be Bangalore.
          </p>
          <h4>20.3 Additional Terms.</h4>
          <p>
            Your use of the Service is subject to all additional terms,
            policies, rules, or guidelines applicable to the Service or certain
            features of the Service that we may post on or link to from the
            Service (the &ldquo;Additional Terms&rdquo;). All Additional Terms
            are incorporated by this reference into, and made a part of, these
            Terms.{" "}
          </p>
          <h4>20.4 Consent to Electronic Communications.</h4>
          <p>
            By using the Service, you consent to receiving certain electronic
            communications from us as further described in our Privacy Statement
            and Cookie Policy. Please read our Privacy Statement and Cookie
            Policy to learn more about our electronic communications practices.
            You agree that any notices, agreements, disclosures, or other
            communications that we send to you electronically will satisfy any
            legal communication requirements, including that those
            communications be in writing.{" "}
          </p>
          <h4>20.5 Contact Information.</h4>
          <p>
            The Service is offered by Clinician OS, Clinician OS You may
            contact us by emailing us at abid@clinicianos.com.
          </p>
          <h4>20.6 No Support.</h4>
          <p>
            We are under no obligation to provide support for the Service. In
            instances where we may offer support, the support will be subject to
            published policies.{" "}
          </p>
          <h3>21. Notice Regarding Apple Inc.</h3>
          <p>
            This Section 21 only applies to the extent you are using our mobile
            application on an iOS device. You acknowledge that these Terms are
            between you and Clinician OS only not with Apple Inc.
            (&ldquo;Apple&rdquo;), and Apple is not responsible for the Service
            or the content thereof. Apple has no obligation to furnish any
            maintenance and support services with respect to the Service. If the
            Service fails to conform to any applicable warranty, you may notify
            Apple and Apple will refund any applicable purchase price for the
            mobile application to you; and, to the maximum extent permitted by
            applicable law, Apple has no other warranty obligation with respect
            to the Service. Apple is not responsible for addressing any claims
            by you or any third party relating to the Service or your possession
            and/or use of the Service, including: (a) product liability claims;
            (b) any claim that the Service fails to conform to any applicable
            legal or regulatory requirement; or (c) claims arising under
            consumer protection or similar legislation. Apple is not responsible
            for the investigation, defence, settlement and discharge of any
            third party claim that the Service and/or your possession and use of
            the Service infringe a third party&rsquo;s intellectual property
            rights. You agree to comply with any applicable third party terms
            when using the Service. Apple and Apple&rsquo;s subsidiaries are
            third party beneficiaries of these Terms, and upon your acceptance
            of these Terms, Apple will have the right (and will be deemed to
            have accepted the right) to enforce these Terms against you as a
            third party beneficiary of these Terms. You hereby represent and
            warrant that: (i) you are not located in a country that is subject
            to a U.S. Government embargo, or that has been designated by the
            U.S. Government as a &ldquo;terrorist supporting&rdquo; country; and
            (ii) you are not listed on any U.S. Government list of prohibited or
            restricted parties.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Terms;
