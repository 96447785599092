import React from "react";

const Contact = () => (
  <section id="contact" className="contact">
    {/* <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Book an appointment!</h2>
        <h3>We would love to hear from you!</h3>
        <p>
          Whether you have a question about{" "}
          <span className="mossai-text">Moss.ai</span> or our services, a
          suggestion for how we can improve, or just want to say hello, our team
          is here to help. You can contact us using the form below, or reach out
          to us directly via phone or email.
        </p>
        <p>
          We aim to respond to all inquiries within 24 hours, and we are
          committed to providing the highest level of customer service. We're
          glad you've stopped by and we're excited to hear from you.
        </p>
      </div> */}
      {/* <!-- Start of Meetings Embed Script --> */}
      {/* <div
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/connect191?embed=true"
      >
        <iframe
          src="https://meetings.hubspot.com/connect191"
          title="meeting"
          width="100%"
          height="800"
        ></iframe>
      </div> */}

      {/* <!-- End of Meetings Embed Script --> */}
    {/* </div> */}
  </section>
);

export default Contact;
